.contact-form-container {
  display: flex;
  flex-wrap: wrap;
  height: auto;
  margin: 0 auto;
}

.contacInformation {
  background-image: url("../Featured Services/images/bg3.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px;
}

.banner {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.banner img {
  width: 100%;
  height: auto;
  object-fit: cover;
  animation: slideUp 10s linear infinite;
}

.form-container {
  flex: 1;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 20px;
}

.form-container h2 {
  margin-bottom: 20px;
  color: #333;
}

.form-container form {
  width: 100%;
}

.form-container label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-container input,
.form-container textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.form-container button {
  padding: 10px 60px;
  border: none;
  border-radius: 25px;
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: transform 0.3s, background-color 0.3s, box-shadow 0.3s;
}

.form-container button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

@keyframes slideUp {
  0% {
    transform: translateY(15px);
  }
  100% {
    transform: translateY(-15px);
  }
}

@media (max-width: 768px) {
  .contact-form-container {
    flex-direction: column;
  }

  .banner {
    order: 2;
  }

  .form-container {
    margin-bottom: 0;
  }
}
@media (min-width: 720px) {
  .submitBtn button {
    margin: 0 auto;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.login-wrapper {
  background-image: url("../Featured Services/images/bg3.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.login-image {
  max-width: 100%;
  height: auto;
}

.loginForm {
  background-color: #fff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  border-bottom: 5px solid green;
}

.loginForm h2 {
  margin-bottom: 20px;
  font-weight: 600;
  color: #333;
}

.loginForm input[type="text"],
.loginForm input[type="email"],
.loginForm input[type="password"],
.loginForm input[type="tel"] {
  padding: 12px 15px;
  margin: 8px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.submit-btn {
  background-color: #4caf50;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
}

.submit-btn:hover {
  background-color: #45a049;
}

@media (max-width: 767px) {
  .loginForm {
    padding: 20px;
    width: 90%;
    margin: 0 auto;
  }

  .login-wrapper {
    padding: 20px;
  }

  .submit-btn {
    padding: 12px;
  }
  .login-image {
    max-width: 100%;
    height: auto;
    margin-top: 147px;
  }
}
@media (max-width: 768px) {
  .login-image {
    max-width: 100%;
    height: auto;
    margin-top: 325px;
  }
}

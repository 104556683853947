#footer {
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.924), rgba(0, 0, 0, 0)),
    url(./footer-bg.819c17fe.jpeg);
  background-size: cover;
  background-repeat: no-repeat;
  font-family: roboto, sans-serif;

  h4 {
    overflow: hidden;
  }
}
.footerLogo {
  width: 70px;
  height: 70px;
}
.copy {
  height: 3rem;
  border-top: 1px solid #5f5f5f;
}
@media (max-width: 575px) {
  .footerRow {
    margin-left: 15px;
  }
}
#footer a {
  color: white;
  &:hover {
    color: #01f965;
    transition: all 0.7s ease;
  }
}

.FeatureCarouselContainer {
  width: 100%;
  padding: 20px;
  background-image: url("./images/bg3.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.FeatureProductSlide {
  background-color: #140735;
  background-repeat: repeat-x;
  background-size: cover;
  padding: 38px 64px;
  border: 3px solid #fff;
  -webkit-backdrop-filter: blur(82.5px);
  backdrop-filter: blur(82.5px);
  border-radius: 20px;
  position: relative;
}

a {
  text-decoration: none;
}

.card {
  margin: 10px;
  background-color: #fff;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.card:hover {
  transform: scale(1.05);
}

.card-img-top {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-bottom: 2px solid #ddd;
}

.card-body {
  text-align: center;
  color: #333;
  padding: 15px;
}

.title {
  color: #fff;
  text-align: center;
  font-size: 2rem;
  margin-bottom: 10px;
}

.subTitle {
  color: #fff;
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.2rem;
}

.Line {
  border-top: 1px solid #fff;
  width: 80%;
  margin: 0 auto 30px;
}

@media (max-width: 1024px) {
  .FeatureProductSlide {
    padding: 20px;
  }

  .carousel-inner {
    flex-direction: column;
  }

  .card {
    margin: 15px auto;
    width: 90%;
  }
}

@media (max-width: 768px) {
  .FeatureProductSlide {
    padding: 20px;
  }

  .carousel-inner {
    flex-direction: column;
  }

  .card {
    margin: 10px 0;
  }

  .card-img-top {
    border-radius: 15px 15px 0 0;
  }

  .title, .subTitle {
    font-size: 1.5rem;
  }
}

@media (max-width: 480px) {
  .FeatureProductSlide {
    padding: 15px;
  }

  .card {
    margin: 5px 0;
  }

  .title {
    font-size: 1.2rem;
  }

  .subTitle {
    font-size: 1rem;
  }
}

* {
  font-family: "Baloo Da 2", "HK Grotesk", "Hind Siliguri";
}

.button-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  gap: 10px;
}

.titleDescription {
  text-align: justify;
}

.button {
  padding: 8px 22px;
  border: none;
  border-radius: 50px;
  background: linear-gradient(90deg, #7f00ff, #e100ff);
  color: white;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

.button:hover {
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
  transform: translateY(-2px);
}

.button.active {
  background-color: #f0e4c3;
  color: #b37700;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.go-home-button {
  background: linear-gradient(90deg, #00b300, #00e600);
}

.content {
  padding: 30px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  color: #333;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.dot,
.check-icon {
  font-size: 20px;
  margin-right: 10px;
}

.dot {
  display: inline-block;
  width: 14px;
  height: 14px;
  background-color: #00b300;
  border-radius: 50%;
  margin-right: 10px;
}

.check-icon {
  color: #b37700;
}

.review-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.rating {
  font-size: 18px;
  margin-bottom: 10px;
}

.comment-section {
  width: 100%;
  margin-bottom: 20px;
}

textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
  font-family: inherit;
  resize: none;
}

.submit-review-btn {
  padding: 10px 20px;
  background-color: #1da1f2;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.submit-review-btn:hover {
  background-color: #0388db;
  transform: translateY(-2px);
}

.check-icon {
  color: white;
  width: 30px;
  height: 30px;
  line-height: 30px;
  background-color: #1887af;
  border-radius: 50%;
  font-size: 13px;
  text-align: center;
}

.service_images .img img {
  width: 100%;
  object-fit: cover;
}

.text-center img {
  max-width: 400px;
  margin: 0 auto;
  display: block;
}
@media (max-width: 768px) {
  .button-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    gap: 10px;
    flex-direction: column;
  }
}
@media (max-width: 320px) {
  .button-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    gap: 10px;
    flex-direction: column;
  }
}
@media (max-width: 1024px) {
  .button-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    gap: 10px;
    flex-direction: column;
  }
}
